import type {
    AccessDelegation,
    AccessImpersonation,
    ApiToken,
    BillingAccount,
    BillingAccountCard,
    BillingAccountHistory,
    BillingAccountInvoice,
    BillingAccountUsage,
    HostPool,
    IpAddress,
    LoadBalancer,
    Location,
    ManagedService,
    PricingList,
    S3Url,
    ServiceImage,
    ServiceSecrets,
    ServiceWhiteList,
    Storage,
    StorageKey,
    User,
    VirtualMachine,
    VmImage,
    VmParameter,
    Vpc,
} from "@warrenio/api-spec/spec.oats.gen";
import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import {
    accessDelegationExample,
    accessImpersonationsExample,
    accessTokensExample,
    generateAccessDelegation,
    generateApiToken,
} from "./data/accessExample.ts";
import { billingAccountCardsExample } from "./data/billingAccountCardsExample.ts";
import { billingAccountsExample, nonSuspendedBillingAccountsExample } from "./data/billingAccountExample.ts";
import { billingAccountHistoryExample } from "./data/billingAccountHistoryExample.ts";
import { billingAccountInvoicesExample } from "./data/billingAccountInvoicesExample.ts";
import { billingAccountUsageExample } from "./data/billingAccountUsageExample.ts";
import { hostPools1, hostPools2 } from "./data/hostPoolsExample.ts";
import { generateIp, ipList1, ipList2 } from "./data/ipAddressesExample.ts";
import { generateLoadBalancer, lbList1, lbList2 } from "./data/loadBalancersExample.ts";
import {
    loc1Tll,
    loc2Jhv,
    loc3Jkt,
    loc4Trtu,
    multiLocationList,
    singleLocationList,
    type ExampleLoc,
} from "./data/locationsExample.ts";
import { vmParametersExample } from "./data/parametersExample.ts";
import {
    freePricingExample,
    largeNumbersRoundingPricingExample,
    missingPricingExample,
    pricingExample,
} from "./data/pricingExample.ts";
import { s3UrlExample } from "./data/s3UrlExample.ts";
import { serviceImagesList } from "./data/serviceImagesExample.ts";
import { serviceSecretsExample, serviceWhitelistExample, servicesList } from "./data/servicesExample.ts";
import { storageList } from "./data/storageExample.ts";
import { storageKeys } from "./data/storageKeyExample.ts";
import { userExample } from "./data/userExample.ts";
import { generateVm, vmList1, vmList2 } from "./data/vmExample.ts";
import { vmImagesExample } from "./data/vmImagesExample.ts";
import { generateNetwork, vpcList1, vpcList2 } from "./data/vpcNetworksExample.ts";
import { defaultMockDbOptions, type DefaultedMockDbOptions, type MockDbOptions } from "./mockDbVariants.ts";
import { randomArray } from "./randomTypes.ts";

/** NB: increment this when database schema/data changes (in {@link defaultDb}). */
export const VERSION = 15;

export interface MockDbData {
    locationsData: LocationsData;

    storages: Storage[];
    storageKeys: StorageKey[];

    services: ManagedService[];
    serviceImages: ServiceImage[];
    serviceWhitelist: ServiceWhiteList;
    serviceSecrets: ServiceSecrets;

    tokens: ApiToken[];
    delegations: AccessDelegation[];
    impersonations: AccessImpersonation[];

    billingAccounts: BillingAccount[];
    billingCards: BillingAccountCard[];
    billingUsage: BillingAccountUsage[];
    billingHistory: BillingAccountHistory[];
    billingInvoices: BillingAccountInvoice[];

    vmImages: VmImage[];

    users: User[];
}

type LocationsData = Record<ExampleLoc, LocationSpecificData>;

export interface LocationSpecificData {
    vms: VirtualMachine[];
    hostPools: HostPool[];

    ipAddresses: IpAddress[];
    vpcNetworks: Vpc[];
    loadBalancers: LoadBalancer[];
}

export interface MockDb extends MockDbData {
    VERSION: number;

    options: MockDbOptions;

    /** Has the data changed from the defaults? */
    modified: boolean;
    /** Next free available ID for a new object. */
    nextId: number;

    // Common data between all variants
    s3Url: S3Url;
    locations: Location[];
    pricing: PricingList;
    vmParameters: VmParameter[];
}

function makeBaseDb(options: DefaultedMockDbOptions) {
    const { singleLocation } = options;
    return {
        VERSION,
        options,
        modified: false,
        // Start at a large number to avoid collisions with constant example data's IDs.
        nextId: 10000,

        // Base data
        s3Url: s3UrlExample,
        locations: singleLocation ? singleLocationList : multiLocationList,
        pricing: makePricingVariant(options),
        vmParameters: vmParametersExample,
    } satisfies Partial<MockDb>;
}

function defaultDb(options: DefaultedMockDbOptions) {
    return {
        locationsData: {
            [loc1Tll]: {
                vms: vmList1,
                ipAddresses: ipList1,
                vpcNetworks: vpcList1,
                loadBalancers: lbList1,
                hostPools: selectHostPools(hostPools1, options),
            },
            [loc2Jhv]: {
                vms: vmList2,
                ipAddresses: ipList2,
                vpcNetworks: vpcList2,
                loadBalancers: lbList2,
                hostPools: selectHostPools(hostPools2, options),
            },
            [loc3Jkt]: makeEmptyLocation(),
            [loc4Trtu]: makeEmptyLocation(),
        },
        storages: storageList,
        storageKeys,
        services: servicesList,
        serviceImages: serviceImagesList,
        serviceWhitelist: serviceWhitelistExample,
        serviceSecrets: serviceSecretsExample,
        tokens: accessTokensExample,
        delegations: accessDelegationExample,
        impersonations: accessImpersonationsExample,
        billingAccounts: makeBillingAccounts(options),
        billingCards: billingAccountCardsExample,
        billingUsage: billingAccountUsageExample,
        billingHistory: billingAccountHistoryExample,
        billingInvoices: billingAccountInvoicesExample,
        vmImages: vmImagesExample,
        users: [userExample],
    } satisfies MockDbData;
}

function makeBillingAccounts({ billingVariant }: DefaultedMockDbOptions) {
    switch (billingVariant) {
        case "all":
            return billingAccountsExample;
        case "notSuspended":
            return nonSuspendedBillingAccountsExample;
        default:
            exhaustiveSwitchCheck(billingVariant);
    }
}

function makeEmptyLocation() {
    return {
        vms: [],
        ipAddresses: [],
        vpcNetworks: [],
        loadBalancers: [],
        hostPools: [],
    } satisfies LocationSpecificData;
}

function selectHostPools(hostPools: HostPool[], { hostPools: options = "multi" }: MockDbOptions) {
    switch (options) {
        case "none":
            return [];
        case "single":
            return [hostPools[0]];
        case "multi":
            return hostPools;
    }
}

function emptyDb() {
    return {
        locationsData: {
            [loc1Tll]: makeEmptyLocation(),
            [loc2Jhv]: makeEmptyLocation(),
            [loc3Jkt]: makeEmptyLocation(),
            [loc4Trtu]: makeEmptyLocation(),
        },
        storages: [],
        storageKeys: [],
        services: [],
        serviceImages: [],
        serviceWhitelist: [],
        serviceSecrets: { sql_pass: "" },
        tokens: [],
        delegations: [],
        impersonations: [],
        billingAccounts: [],
        billingCards: [],
        billingUsage: [],
        billingHistory: [],
        billingInvoices: [],
        vmImages: [],
        users: [userExample],
    } satisfies MockDbData;
}

function makePricingVariant({ pricingVariant }: DefaultedMockDbOptions) {
    switch (pricingVariant) {
        case "default":
            return pricingExample;
        case "free_prices":
            return freePricingExample;
        case "missing_prices":
            return missingPricingExample;
        case "large_numbers_rounding":
            return largeNumbersRoundingPricingExample;
        default:
            exhaustiveSwitchCheck(pricingVariant);
    }
}

export function bigDb(options: DefaultedMockDbOptions, count = 100) {
    const baseDb = defaultDb(options);

    const makeRandomLocation = (slug: keyof typeof baseDb.locationsData) => {
        const perLocationCount = Math.ceil(count / 2);
        const networkCount = Math.ceil(perLocationCount / 3);

        const baseLocation = baseDb.locationsData[slug];
        return {
            ...baseLocation,

            vms: randomArray(perLocationCount, generateVm),
            ipAddresses: randomArray(networkCount, generateIp),
            vpcNetworks: randomArray(networkCount, () => generateNetwork(baseLocation.vpcNetworks)),
            loadBalancers: slug === loc1Tll ? randomArray(networkCount, generateLoadBalancer) : [],
        };
    };

    return {
        ...baseDb,

        // Set as modified so it is not regenerated on page reload, since due to randomness it would have different data every time.
        modified: true,

        locationsData: {
            [loc1Tll]: makeRandomLocation(loc1Tll),
            [loc2Jhv]: makeRandomLocation(loc2Jhv),
            [loc3Jkt]: makeEmptyLocation(),
            [loc4Trtu]: makeEmptyLocation(),
        },

        tokens: randomArray(count, generateApiToken),
        delegations: randomArray(count, generateAccessDelegation),
    } satisfies MockDbData & Partial<MockDb>;
}

function metalDb(_options: DefaultedMockDbOptions) {
    return {
        ...emptyDb(),
    } satisfies MockDbData;
}

export function buildMockDb(options: MockDbOptions = {}): MockDb {
    const defaultedOptions = { ...defaultMockDbOptions, ...options };
    const { variant = "default" } = defaultedOptions;
    const base = makeBaseDb(defaultedOptions);
    switch (variant) {
        case "default":
            return { ...base, ...defaultDb(defaultedOptions) };
        case "empty":
            return { ...base, ...emptyDb() };
        case "unauthorized":
            return { ...base, ...defaultDb(defaultedOptions) };
        case "big":
            return { ...base, ...bigDb(defaultedOptions) };
        case "metal":
            return { ...base, ...metalDb(defaultedOptions) };
        default:
            exhaustiveSwitchCheck(variant);
    }
}
