export const mockDbVariants = {
    default: "Default variant",
    metal: "Bare Metal variant",
    empty: "Empty variant",
    unauthorized: "Unauthorized response mock",
    big: "Big variant",
} as const;

export const billingVariants = {
    notSuspended: "Not suspended accounts",
    all: "All accounts",
} as const;

export const pricingVariants = {
    default: "Default prices",
    free_prices: "With free prices",
    missing_prices: "With missing price rules",
    large_numbers_rounding: "Prices in large numbers",
} as const;

export type MockDbVariant = keyof typeof mockDbVariants;

export type BillingVariant = keyof typeof billingVariants;

export type PricingVariant = keyof typeof pricingVariants;

export interface DefaultedMockDbOptions {
    variant: MockDbVariant;
    billingVariant: BillingVariant;
    pricingVariant: PricingVariant;
    singleLocation: boolean;
    hostPools: "none" | "single" | "multi";
}

export type MockDbOptions = Partial<DefaultedMockDbOptions>;

export const defaultMockDbOptions: DefaultedMockDbOptions = {
    variant: "default",
    billingVariant: "notSuspended",
    pricingVariant: "default",
    singleLocation: false,
    hostPools: "multi",
};
