import type { User } from "@warrenio/api-spec/spec.oats.gen";

export const userExample: User = {
    cookie_id: "112f7172c3da4098af4013cffd214a14",
    id: 29,
    last_activity: "2019-05-08 13:15:21",
    name: "mock-username@warren.io",
    profile_data: {
        user_id: 29,
        last_name: "(last)",
        lang: "en",
        personal_id_number: "123",
        avatar: "",
        updated_at: "2024-06-03 12:07:09.964646",
        id: 23,
        first_name: "(first)",
        email: "mock-email@warren.io",
        phone_number: "",
        created_at: "2018-11-01 13:04:30.706940",
    },
    signup_site: "Pilw",
};

export const minimalUserExample: User = {
    cookie_id: "81c16fd16d53dc31279bb1b27b871c8371cdec225984d88db38e0714",
    id: 447,
    name: "minimal@oyenetwork.com",
    profile_data: {
        id: 431,
        first_name: "Minimal",
        email: null,
        phone_number: null,
        avatar: null,
        created_at: "2024-06-10 16:07:11.543921",
        last_name: "Guy",
        user_id: 447,
        lang: "en",
        personal_id_number: null,
        updated_at: "2024-06-10 16:07:47.572828",
    },
    signup_site: "Warren Light",
};
